import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { ImprintTemplate } from '../templates/imprint-template.js'

const ImprintPage = ({data}) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <ImprintTemplate
            imprint = { frontmatter }
        />
    )
}

ImprintPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
          frontmatter: PropTypes.object,
        }),
      }),
}

export default ImprintPage

export const pageQuery = graphql`
query ImprintTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "imprint-page" }}) {
        frontmatter {
            text
        }
    }
}
`