import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'
import Spacer from '../components/Spacer'

const TextContainer = styled.div`
width:90%;
max-width:900px;
margin:auto;

& h1{
    font-size:24px;
    font-family:Open Sans;
    font-weight:600;
}

& h2, h3{
    position:relative;
    font-size:20px;
    font-family:Open Sans;
    font-weight:400;
    margin-top:30px;

    &:after{
        content:'';
        position:absolute;
        bottom:-4px;
        left:5px;
        width:30px;
        height:2px;
        background-color:var(--tmain);
    }
}

& p{
    font-size:16px;
}

@media(min-width:983px){
    & h1{
        font-size:36px;
    }

    & h2, h3{
        font-size:26px;
        margin-top:40px;

        &:after{
            width:0;
        }
    }

    & p{
        font-size:18px;
    }
}
`

const AdminButton = styled.a`
position:relative;
display:block;
text-decoration:none;
width:200px;
border:2px solid var(--tmain);
background-color:var(--tmain);
padding:6px 24px;
border-radius:5px;
color:white;
font-weight:700;
text-align:center;
margin:auto;
font-size:18px;
transition: 0.2s ease;
cursor:pointer;
transition:background-color .3s ease, color .3s ease;

&:hover {
    background-color:rgba(0,0,0,0);
    color: var(--tmain);
}
`

export const ImprintTemplate = ({
    imprint
}) => (
    <div>
        <Helmet defer={false}>
            <title>Impressum</title>
            <meta name="robots" content="noindex"/>
        </Helmet>
        <Layout>
            <Spacer height={100}/>
            <TextContainer>
                <ReactMarkdown>{imprint.text}</ReactMarkdown>
            </TextContainer>
            <Spacer height={150}/>
            <AdminButton href="/admin">CMS-Login</AdminButton>
            <Spacer height={150}/>
        </Layout>
    </div>
)

ImprintTemplate.propTypes = {
}